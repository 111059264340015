var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Profile__General__Page" },
    [
      _c("div", { staticClass: "Profile__General__Page__Header" }, [
        _c("h1", [_vm._v("הגדרות לקורס " + _vm._s(_vm.course.name))]),
        _c(
          "div",
          { staticClass: "loading" },
          [
            _c("b-loading", {
              attrs: { "is-full-page": true, "can-cancel": false },
              model: {
                value: _vm.isLoading,
                callback: function($$v) {
                  _vm.isLoading = $$v
                },
                expression: "isLoading"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-field",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { label: "סטטוס קורס" }
        },
        [
          _c(
            "b-checkbox",
            {
              staticClass: "checkbox",
              attrs: { label: "", type: "is-link is-light" },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(" " + _vm._s(_vm.model.status ? " פעיל" : "לא פעיל") + " ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form" },
        [
          _c("b-field", { attrs: { label: "על הקורס" } }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("ckeditor", {
                  attrs: { editor: _vm.editor, config: _vm.editorConfig },
                  model: {
                    value: _vm.model.description,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "description", $$v)
                    },
                    expression: "model.description"
                  }
                })
              ],
              1
            )
          ]),
          _c("b-field", { attrs: { label: "סילבוס" } }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("ckeditor", {
                  attrs: { editor: _vm.editor, config: _vm.editorConfig },
                  model: {
                    value: _vm.model.syllabus,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "syllabus", $$v)
                    },
                    expression: "model.syllabus"
                  }
                })
              ],
              1
            )
          ]),
          _c("b-field", { attrs: { label: "פתיח" } }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("ckeditor", {
                  attrs: { editor: _vm.editor, config: _vm.editorConfig },
                  model: {
                    value: _vm.model.openingRemarks,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "openingRemarks", $$v)
                    },
                    expression: "model.openingRemarks"
                  }
                })
              ],
              1
            )
          ]),
          _c("b-field", { attrs: { label: "קבצים" } }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("ckeditor", {
                  attrs: { editor: _vm.editor, config: _vm.editorConfig },
                  model: {
                    value: _vm.model.courseFilesContent,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "courseFilesContent", $$v)
                    },
                    expression: "model.courseFilesContent"
                  }
                })
              ],
              1
            )
          ]),
          _c("b-field", { attrs: { label: "תנאי שימוש" } }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c("ckeditor", {
                  attrs: { editor: _vm.editor, config: _vm.editorConfig },
                  model: {
                    value: _vm.model.termsOfUser,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "termsOfUser", $$v)
                    },
                    expression: "model.termsOfUser"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "b-field",
            { attrs: { label: "ניהול משפטי מוטיבציה" } },
            [
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.model.showMotivationText,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showMotivationText", $$v)
                    },
                    expression: "model.showMotivationText"
                  }
                },
                [_vm._v(" הצגת משפטי מוטיבציה בעמוד הבית של הקורס ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "save-btn",
          attrs: { type: "is-info" },
          on: { click: _vm.updateCourse }
        },
        [_vm._v(" שמור ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }