<template>
  <div class="Profile__General__Page">
    <div class="Profile__General__Page__Header">
      <h1>הגדרות לקורס {{ course.name }}</h1>
      <div class="loading">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </div>
    </div>
    <b-field style="margin-top: 10px;" label="סטטוס קורס">
      <b-checkbox  v-model="model.status" label="" type="is-link is-light" class="checkbox">
        {{ model.status ? ' פעיל' : 'לא פעיל' }}
      </b-checkbox>
    </b-field>
    <div class="Profile__General__Page__Form">
      <b-field label="על הקורס">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.description" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="סילבוס">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.syllabus" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="פתיח">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.openingRemarks" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="קבצים">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.courseFilesContent" :config="editorConfig"></ckeditor>
        </div>
      </b-field>

      <b-field label="תנאי שימוש">
        <div class="item">
          <ckeditor :editor="editor" v-model="model.termsOfUser" :config="editorConfig"></ckeditor>
        </div>
      </b-field>
      <b-field label="ניהול משפטי מוטיבציה">
        <b-checkbox v-model="model.showMotivationText">
          הצגת משפטי מוטיבציה בעמוד הבית של הקורס
        </b-checkbox>
      </b-field>
    </div>
    <b-button class="save-btn" type="is-info" @click="updateCourse">
      שמור
    </b-button>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AdminService from "../services/AdminService";

export default {
  name: "ProfileGeneral",
  data() {
    return {
      isLoading: false,
      model: {
        description: '',
        syllabus: '',
        openingRemarks: '',
        courseFilesContent: '',
        termsOfUser: '',
        ShowMotivationText: null
      },
      editor: ClassicEditor,
      editorConfig: {
        language: {
          ui: 'he',
        },
        // toolbar: {
        //   items: [
        //     'undo',
        //     'redo',
        //     '|',
        //     'heading',
        //     '|',
        //     'fontFamily',
        //     'fontSize',
        //     'fontBackgroundColor',
        //     'fontColor',
        //     '|',
        //     'bold',
        //     'italic',
        //     'underline',
        //     'strikethrough',
        //     'removeFormat',
        //     '|',
        //     'outdent',
        //     'indent',
        //     'alignment',
        //     '|',
        //     'numberedList',
        //     'bulletedList',
        //     'link',
        //     '|',
        //     'blockQuote',
        //     'insertTable',
        //   ],
        // },
      }
    };
  },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    }
  },
  created() {
    this.isLoading = true;
    AdminService.getCourseSettings(this.course.id)
      .then((r) => {
        this.model = r.data;
        this.isLoading = false;
      });
  },
  methods: {
    updateCourse() {
      this.isLoading = true;
      AdminService.updateCourseGeneralSettings(this.course.id, this.model)
        .then((r) => {
          this.model = r.data;
          Toast.open({
            type: "is-success",
            message: "הפרטים נשמרו בהצלחה!",
            position: "is-top",
            duration: 4000,
          });
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "הפעולה לא התבצעה. יש לנסות שוב.",
            position: "is-bottom",
            duration: 8000,
          });
        }).finally(() => {
          this.isLoading = false;
        });
    },
    save() {
      this.isLoading = true;
      this.updateGeneral(this.model);
      this.saveProfile()
        .then(() => {
          Toast.open({
            type: "is-success",
            message: "הפרטים נשמרו בהצלחה!",
            position: "is-top",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "הפעולה לא התבצעה. יש לנסות שוב.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.item {
  max-height: 400px;
  overflow: auto;
}

.Profile__General__Page {
  padding: 20px 40px;

  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;

    h1 {
      font-size: 30px;
    }
  }

  .Profile__General__Page__Form {
    margin-top: 20px;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    gap: 30px;

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      ::v-deep {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }

        input {
          background-color: #f5f8fa;
          box-shadow: none;
          border: 1px solid #cbd6e2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }
    }

    .Profile__General__Page__Form__Actions {
      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom 0.2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }

        &:active {
          transform: scale(0.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
